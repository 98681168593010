<template>
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title>
        <h3 class="headline mb-0">{{ $t('title.carteira_cliente') }}</h3>
      </v-card-title>
      <v-container grid-list-xl fluid>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              class="Form-text__invalid"
              id="id_externo"
              name="id_externo"
              v-model="carteira.idExterno"
              :label="`${$t('label.codigo')} *`"
              :counter="50"
              maxlength="50"
              :rules="[rules.required]"
              :readonly="somenteLeitura">
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              class="Form-text__invalid"
              id="descricao"
              name="descricao"
              v-model="carteira.descricao"
              :label="`${$t('label.descricao')} *`"
              :counter="100"
              maxlength="100"
              :rules="[rules.required]"
              :readonly="somenteLeitura">
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="temUnidadeNegocio">
            <v-select
              class="Form-text__invalid"
              id="unidade_negocio"
              name="unidade_negocio"
              item-text="nomExtensao"
              item-value="id"
              clearable
              :readonly="somenteLeitura"
              v-model="carteira.dependencias.idUnidadeNegocio"
              :rules="unidadeNegocio.depObrigatoria ? [rules.required] : []"
              :items="unidades"
              :label="`${$tc('label.unidade_negocio', 1)} ${unidadeNegocio.depObrigatoria ? '*' : ''}`">
            </v-select>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="cancelar">{{ $t('label.cancelar') }}</v-btn>
        <v-btn @click.native="abrirDialogSalvar" color="primary">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>
    </v-card>
    <confirm
      ref="confirmDialog"
      :message="$t('message.deseja_salvar', {text: $t('label.carteira_cliente')})"
      :persistent="true"
      @agree="salvar">
    </confirm>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';

export default {
  name: 'CarteiraClienteForm',
  components: {
    Confirm,
  },
  props: {
    idCarteiraCliente: {
      type: Number,
      default: null,
    },
    somenteLeitura: true,
  },
  data() {
    return {
      unidades: [],
      tipos: [],
      carteira: {
        id: null,
        idExterno: null,
        indAtivo: false,
        descricao: null,
        dependencias: {},
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      resources: this.$api.carteiraCliente(this.$resource),
      resourcesExtensao: this.$api.extensao(this.$resource),
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getCarteiraClienteMetadado',
    ]),
    possuiVinculos() {
      return !!(this.carteira && this.carteira.hascliente);
    },
    temUnidadeNegocio() {
      return !!this.getCarteiraClienteMetadado
        && !!this.getCarteiraClienteMetadado.mapaEntidades
        && !!this.getCarteiraClienteMetadado.mapaEntidades.unidadenegocio;
    },
    unidadeNegocio() {
      if (this.temUnidadeNegocio) {
        return this.getCarteiraClienteMetadado.mapaEntidades.unidadenegocio;
      }
      return null;
    },
  },
  methods: {
    abrirDialogSalvar() {
      if (!this.$refs.form.validate()) return;
      this.$refs.confirmDialog.open();
    },
    salvar() {
      if (this.idCarteiraCliente) {
        this.resources.atualizar({ id: this.idCarteiraCliente }, this.carteira).then(() => {
          this.$toast(this.$t('message.atualizado_confira_tabela'));
          this.$emit('atualizarGrid');
        }, (err) => {
          this.$error(this, err);
        });
      } else {
        this.resources.gravar(this.carteira).then(() => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          this.$emit('atualizarGrid');
        }, (err) => {
          this.$error(this, err);
        });
      }
    },
    cancelar() {
      this.$refs.form.reset();
      this.$emit('cancelar');
    },
    carregar() {
      const id = this.idCarteiraCliente;
      this.resources.obter({ id }).then((response) => {
        this.carteira = response.data;
      }, (err) => {
        this.$error(this, err);
        this.cancelar();
      });
    },
    preencherUnidades() {
      if (!this.temUnidadeNegocio) return;
      const value = {
        nome: '',
        tamanhoPagina: 2000,
        idNivelExtensao: this.unidadeNegocio.idNivelExtensao,
      };

      this.resourcesExtensao.listarAtivos(value).then((response) => {
        this.unidades = response.data.resposta;
      }, (err) => {
        this.$error(this, err);
        this.cancelar();
      });
    },
  },
  mounted() {
    this.preencherUnidades();
    if (this.idCarteiraCliente) {
      this.carregar();
    }
  },
};
</script>
