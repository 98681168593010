<template>
  <div>
    <v-container grid-list-xl fluid>
      <v-row justify="end">
        <v-col cols="12" sm="6" md="9">
          <h3 class="headline mb-0">{{ $t('title.carteira_cliente') }}</h3>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-tooltip bottom v-if="itensSelecionados && itensSelecionados.length && hasErrors">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" large color="yellow darken-3" slot="activator">warning</v-icon>
            </template>
            <span>{{ $t('message.InativarCarteiraCliente')}}</span>
          </v-tooltip>
          <v-btn
            v-else-if="itensSelecionados && itensSelecionados.length"
            text icon
            @click="requestAtivarInativar">
            <v-icon>block</v-icon>
          </v-btn>
          <v-text-field v-else
            v-model="search"
            @input="filtrar"
            append-icon="search"
            :label="$t('label.pesquisar')"
            single-line
            clearable
            hide-details>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      v-model="itensSelecionados"
      :must-sort="true"
      class="elevation-1"
      :headers="headersDinamico"
      :items="carteiras" item-key="id"
      :options.sync="pagination"
      show-select
      v-bind:class="{ 'controls-puller': carteiras.length > 5 }"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50],
      }">
      <template v-slot:item.acoes="{ item }">
        <v-menu bottom left>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              class="grey--text text--darken-1">
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="verClientes(item)">
              <v-list-item-action>
                <v-icon>people_outline</v-icon>
              </v-list-item-action>
              <v-list-item-title class="grey--text text--darken-1">{{ $t('label.visualizar_cliente') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="verDivisoes(item)">
              <v-list-item-action>
                <v-icon>filter_list</v-icon>
              </v-list-item-action>
              <v-list-item-title class="grey--text text--darken-1">{{ $t('label.visualizar_divisao') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="editItem(item)" v-if="!somenteLeitura">
              <v-list-item-action>
                <v-icon>edit</v-icon>
              </v-list-item-action>
              <v-list-item-title class="grey--text text--darken-1">{{ $t('label.editar_carteira_cliente') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.id_externo="{ item }">
        {{ item.idExterno }}
      </template>
      <template v-slot:item.unidadenegocio="{ item }" v-if="temUnidadeNegocio">
        {{ item.unidadenegocio }}
      </template>
      <template v-slot:item.ind_ativo="{ item }">
        {{ item.indAtivo ? $t('label.ativo') : $t('label.inativo') }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';

export default {
  name: 'CarteiraClienteTabela',
  components: {
    Confirm,
  },
  props: {
    somenteLeitura: true,
  },
  data() {
    return {
      search: '',
      resources: this.$api.carteiraCliente(this.$resource),
      esperar: false,
      pagination: {},
      totalPage: 0,
      headers: [
        { text: this.$tc('label.codigo', 1), value: 'id_externo' },
        { text: this.$tc('label.descricao', 1), value: 'descricao' },
        { text: this.$tc('label.status', 1), value: 'ind_ativo' },
      ],
      itensSelecionados: [],
      carteiras: [],
    };
  },
  watch: {
    search() {
      if (this.timeoutTemp) {
        window.clearTimeout(this.timeoutTemp);
      }
      this.timeoutTemp = window.setTimeout(() => {
        this.filtrar();
      }, 500);
    },
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
  },
  computed: {
    hasErrors() {
      return this.itensSelecionados.some((el) => el.hascliente || el.hasdivisao);
    },
    headersDinamico() {
      const headers = Object.assign([], this.headers);
      if (this.temUnidadeNegocio) {
        headers.push({ text: this.$tc('label.unidade_negocio', 1), value: 'unidadenegocio', sortable: false });
      }
      if (!this.somenteLeitura) {
        headers.unshift({
          text: '', value: 'acoes', sortable: false, width: '2%',
        });
      }

      return headers;
    },
    ...mapGetters('metadados', [
      'getCarteiraClienteMetadado',
    ]),
    temUnidadeNegocio() {
      return !!this.getCarteiraClienteMetadado
        && !!this.getCarteiraClienteMetadado.mapaEntidades
        && !!this.getCarteiraClienteMetadado.mapaEntidades.unidadenegocio;
    },
  },
  methods: {
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 1000);
    },
    requestAtivarInativar() {
      const requests = this.itensSelecionados.map((el) => {
        const payload = {
          ...el,
          indAtivo: !el.indAtivo,
        };
        return this.resources.atualizar({ id: el.id }, payload);
      });
      Promise.all(requests)
        .then(() => {
          const msg = this.$tc('label.registro_atualizado_com_sucesso', requests.length === 1 ? 1 : 2);
          this.$toast(msg);
          this.itensSelecionados = [];
          this.buscar();
        }, (err) => this.$error(this, err));
    },
    verClientes(item) {
      this.$emit('verClientes', item.id);
    },
    verDivisoes(item) {
      this.$emit('verDivisoes', item.id);
    },
    editItem(item) {
      this.$emit('edit', item.id);
    },
    buscar() {
      if (this.search) this.pagination.page = 1;

      const params = {
        filter: this.search,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      this.resources.listar(params).then((response) => {
        this.carteiras = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
  },
  mounted() {
    this.filtrar();
  },
};
</script>

<style>
  .controls-puller .v-data-footer {
    padding-right: 60px;
  }
</style>
