<template>
  <v-card>
    <v-card-title>
      <h3 class="headline mb-0">{{ $tc('label.divisao', 1) }}</h3>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          v-model="search"
          @input="filtrar"
          append-icon="search"
          :label="$t('label.pesquisar')"
          single-line
          clearable
          hide-details>
        </v-text-field>
      </v-col>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="divisoes"
      :options.sync="pagination"
      :server-items-length="totalPage"
      class="elevation-1"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50],
      }">
      <template v-slot:item.nom_divisao="{ item }">
        {{ item.nomDivisao }}
      </template>
      <template v-slot:item.nom_estrutura_usuario="{ item }">
        {{ item.nomEstruturaUsuario }}
      </template>
    </v-data-table>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click.native="cancelar" color="accent">{{ $t('label.cancelar') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { generateComputed } from '../../common/functions/roles-computed-generator';

export default {
  name: 'DivisoesCarteiraClientes',
  props: {
    idCarteiraCliente: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      divisoes: [],
      headers: [
        { text: this.$tc('label.nome', 1), value: 'nom_divisao' },
        { text: this.$tc('label.estrutura_usuario', 1), value: 'nom_estrutura_usuario' },
      ],
      search: '',
      resources: this.$api.carteiraCliente(this.$resource),
      esperar: false,
      pagination: {},
      totalPage: 0,
    };
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
  },
  computed: {
    ...generateComputed('Divisao', [
      'canAccessPage',
      'canAccessCRUD',
    ]),
  },
  methods: {
    cancelar() {
      this.$emit('cancelar');
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 1000);
    },
    buscar() {
      const params = {
        id: this.idCarteiraCliente,
        filter: this.search,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      this.resources.obterDivisoes(params).then((response) => {
        this.divisoes = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
  },
  mounted() {
    this.filtrar();
  },
};
</script>
