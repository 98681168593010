<template>
  <div>
    <carteira-cliente-tabela
      @edit="edit"
      ref="tabela"
      @verClientes="verClientes"
      @verDivisoes="verDivisoes"
      :somente-leitura="!canAccessCRUD">
    </carteira-cliente-tabela>
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-btn v-if="canAccessCRUD" v-on="on" @click="abrirForm" fixed fab bottom right color="primary" class="v-btn__fab">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('label.cadastrar_carteira_cliente') }}</span>
    </v-tooltip>
    <v-dialog v-model="dialogForm" persistent max-width="1200">
      <carteira-cliente-form v-if="dialogForm" :id-carteira-cliente="idCarteiraCliente"
                             :somente-leitura="!canAccessCRUD" @cancelar="fecharForm"
                             @atualizarGrid="buscar">
      </carteira-cliente-form>
    </v-dialog>
    <v-dialog v-model="dialogClienteForm" max-width="1200">
      <clientes-carteira-clientes v-if="dialogClienteForm" :id-carteira-cliente="idCarteiraCliente"
                             @cancelar="fecharForm">
      </clientes-carteira-clientes>
    </v-dialog>
    <v-dialog v-model="dialogDivisaoForm" max-width="1200">
      <divisoes-carteira-clientes v-if="dialogDivisaoForm" :id-carteira-cliente="idCarteiraCliente"
                             @cancelar="fecharForm">
      </divisoes-carteira-clientes>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import CarteiraClienteTabela from './CarteiraClienteTabela';
import CarteiraClienteForm from './CarteiraClienteForm';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import ClientesCarteiraClientes from './ClientesCarteiraClientes';
import DivisoesCarteiraClientes from './DivisoesCarteiraClientes';

export default {
  name: 'CarteirasClientes',
  data() {
    return {
      dialogForm: false,
      dialogClienteForm: false,
      dialogDivisaoForm: false,
      idCarteiraCliente: null,
      resourceMetadado: this.$api.metadado(this.$resource),
    };
  },
  components: {
    ClientesCarteiraClientes,
    CarteiraClienteForm,
    CarteiraClienteTabela,
    DivisoesCarteiraClientes,
  },
  computed: {
    ...generateComputed('Carteira', [
      'canAccessPage',
      'canAccessCRUD',
    ]),
    ...mapGetters('metadados', [
      'getCarteiraClienteMetadado',
    ]),
  },
  methods: {
    ...mapActions('metadados', [
      'setCarteiraClienteMetadado',
    ]),
    popularMetadados() {
      if (this.getCarteiraClienteMetadado) {
        return;
      }
      this.setCarteiraClienteMetadado({
        resource: this.resourceMetadado.listarCarteiraCliente,
      });
    },
    verClientes(id) {
      this.idCarteiraCliente = id;
      this.dialogClienteForm = true;
    },
    verDivisoes(id) {
      this.idCarteiraCliente = id;
      this.dialogDivisaoForm = true;
    },
    edit(id) {
      this.idCarteiraCliente = id;
      this.abrirForm();
    },
    buscar() {
      this.fecharForm();
      this.$refs.tabela.filtrar();
    },
    abrirForm() {
      this.dialogForm = true;
    },
    fecharForm() {
      this.idCarteiraCliente = null;
      this.dialogForm = false;
      this.dialogClienteForm = false;
      this.dialogDivisaoForm = false;
    },
  },
  beforeMount() {
    this.popularMetadados();
  },
};
</script>
